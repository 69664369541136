import React, { Component } from 'react';

import ChevronIcon from '../../images/Chevron';

import style from '../CollapsiblePanel/CollapsiblePanel.scss';

import cn from 'classnames';

export interface ICollapsiblePanelProps {
  className?: string;
  headlineClassName?: string;
  contentClassName?: string;
  headline: React.ReactNode;
  children?: React.ReactNode;
  iconElement?: React.ReactNode;
  index?: number;
  onToggle?: (index?: number) => void;
  isExpanded?: boolean;
}

interface ICollapsiblePanelState {
  isAnimating: boolean;
  maxHeight: number;
}

class CollapsiblePanel extends Component<ICollapsiblePanelProps, ICollapsiblePanelState> {
  animatingTimeout?: NodeJS.Timer;
  contentWrapper = React.createRef<HTMLDivElement>();

  constructor(props: ICollapsiblePanelProps) {
    super(props);

    this.state = {
      isAnimating: false,
      maxHeight: 0,
    };
  }

  componentDidMount() {
    const { isExpanded } = this.props;

    window.addEventListener('resize', this.handleHeightChange);
    // If by default it is expanded, calculate max-height.
    if (isExpanded) {
      this.handleHeightChange();
    }
  }

  componentDidUpdate(prevProps: ICollapsiblePanelProps) {
    const { isExpanded } = this.props;

    if (isExpanded !== prevProps.isExpanded) {
      const { scrollHeight = 0 } = this.contentWrapper.current || {};
      // Set reasonable limits for animation duration.
      // Determine maxHeight at the time of the click,
      // so that it's the most accurate (consider the situation
      // when content has changed after component mounted).

      const maxHeight = isExpanded ? scrollHeight : 0;

      this.setState({
        isAnimating: true,
        maxHeight,
      });

      this.animatingTimeout = setTimeout(() => {
        this.setState({ isAnimating: false });
      }, 300);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleHeightChange);
    if (this.animatingTimeout !== undefined) {
      clearTimeout(this.animatingTimeout);
    }
  }

  handleHeightChange = () => {
    const { isExpanded } = this.props;
    const { isAnimating, maxHeight } = this.state;

    if (isExpanded && !isAnimating && this.contentWrapper !== null) {
      const { scrollHeight = 0 } = this.contentWrapper.current || {};

      if (scrollHeight !== maxHeight) {
        this.setState({ maxHeight: scrollHeight });
      }
    }
  };

  handleClick = () => {
    const { onToggle, index } = this.props;
    if (typeof onToggle === 'function') {
      onToggle(index);
    }
  };

  render() {
    const {
      className,
      headlineClassName,
      contentClassName,
      isExpanded,
      iconElement,
      headline,
      children,
      ...restProps
    } = this.props;

    const { isAnimating, maxHeight } = this.state;

    return (
      <div
        className={cn(style.collapsiblePanel, className)}
        onClick={this.handleClick}
        data-testid="collapsiblePanel"
        data-collapsed={!isExpanded}
        {...restProps}
      >
        <header className={cn(headlineClassName, style.headlineWrapper)}>
          <h5 className={style.headlineInner}>{headline}</h5>
          {iconElement ? (
            <div className={style.iconElement}>{iconElement}</div>
          ) : (
            <ChevronIcon className={style.chevronIcon} rotate={isExpanded ? -90 : 90} />
          )}
        </header>

        <div
          ref={this.contentWrapper}
          className={style.contentWrapper}
          style={{
            // Make overflow visible when panel is expanded in case some element
            // such as a tooltip overflows contentWrapper container.
            maxHeight,
            overflow: isExpanded && !isAnimating ? 'visible' : 'hidden',
            transition: 'max-height 300ms ease-in-out',
          }}
        >
          <div className={contentClassName}>{children}</div>
        </div>
      </div>
    );
  }
}

export default CollapsiblePanel;
