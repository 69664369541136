import React from 'react';

import BigBanner from '../components/Banners/BigBanner';
import CollapsiblePanel from '../components/CollapsiblePanel/CollapsiblePanelList';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import CrossIcon from '../images/Cross';

import style from '../styles/pages/faq.scss';

interface IFaqProps {
  data: {
    allGhostPost: any;
  };
  location: {
    pathname: string;
  };
}

const Faq = ({ data, location }: IFaqProps) => {
  const isActive = (isActive: boolean) => {
    return isActive ? 45 : 0;
  };

  const callouts = [
    {
      content:
        'A network where publishers and advertisers can operate in a cohesive eco-system. As the first network of its kind, we connect all aspects of the lead generation process where purchasing and selling leads is easier than ever.',
      heading: 'What is Lead Economy?',
    },
    {
      content:
        'A publisher generates leads through digital marketing efforts such as email marketing, search engine marketing, SEO, web publishing, etc.',
      heading: 'What is a publisher?',
    },
    {
      content:
        'If you own a website or manage digital marketing campaigns with quality traffic, you could be eligible to join one of the fastest growing lead verticals in the industry. <a href="/affiliate">Click here</a>.',
      heading: 'How can I become a publisher?',
    },
    {
      content: 'Yes - incentive, co-registration and adult traffic are not accepted.',
      heading: 'Are there certain types of traffic not accepted by Lead Economy?',
    },
    {
      content:
        'Yes, leads are delivered in real-time through our state of the art tracking platform.',
      heading: 'Are my leads delivered in real time?',
    },
    {
      content:
        'Our partners are an integral part of our success, so we believe that having a direct line to a dedicated Account Manager is vital for your growth and success. See more of what makes us different <a href="/affiliate">here</a>.',
      heading: 'How is Lead Economy’s affiliate program managed? ',
    },
    {
      content:
        'Publishers can generate leads in a variety of ways including email marketing, web publishing, content marketing, pay per click advertising, and search engine marketing.',
      heading: 'What methods can I use to generate leads?',
    },
    {
      content:
        'Lead Economy generates payments on a weekly net 8. Once your W9/W8 has been submitted, you are eligible to receive payments from our network. Flexible options are provided to accommodate your needs including bank wire transfer, ACH deposit, PayPal, WebMoney and check.',
      heading: 'I’m a publisher, how do I get paid and when?',
    },
    {
      content: 'Yes, absolutely. We currently work with publishers across the globe.',
      heading: 'Can international affiliates join Lead Economy?',
    },
    {
      content:
        'By working with Lead Economy, you have access to exclusive offers only available to our publishers. This helps to reduce competition, duplicate leads and increase conversion rates and revenue.',
      heading: 'What does it mean when an offer is exclusive?',
    },
    {
      content:
        "Yes. Lead Economy advertisers can set custom filters, allowing you to purchase only the leads that match your business' needs.",
      heading: 'Can I set filters when buying leads?',
    },
    {
      content:
        'Your account can be approved in as little as a few hours pending accurate information on the initial application <a href="/affiliate">leadeconomy.com/affiliate</a> and contact with an Account Manager.',
      heading: 'How long does it take for my account to be approved?',
    },
    {
      content:
        'At Lead Economy, our dedicated design team provides creatives for all traffic types.',
      heading: 'What kind of promotional materials do you offer?',
    },
    {
      content: 'Yes, Lead Economy offers a 5% referral fee.',
      heading: 'Do you have a referral program if I bring other affiliates on-board?',
    },
    {
      content:
        'An advertiser is a business or individual who owns the products or services lead producers advertise. We work directly with lead buyers to increase sales and ROI by generating high quality leads. <a href="/brands">Click here</a>.',
      heading: 'What is an advertiser?',
    },
    {
      content:
        'Our quick and simple online registration form allows for easy submission. <a href="/brands">Click here</a> to get started, and a representative will get back to you shortly with next steps.',
      heading: 'How can I become an advertiser?',
    },
    {
      content: 'Contact your dedicated Account Manager or click contact above to send us an email.',
      heading: 'I still have questions, what now?',
    },
  ];

  return (
    <>
      <MetaData location={location} data={{}} />
      <Layout isHome headerClassName={style.header}>
        <section className={style.faq}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12">
                <h2>
                  Want to learn more? <br className="d-none d-sm-block" /> We’re an open book.
                </h2>
                <div className="row">
                  <div className="col-12 col-lg-8 offset-lg-2">
                    <CollapsiblePanel
                      callouts={callouts}
                      className={style.collapsible}
                      contentClassName={style.collapsibleContent}
                      headlineClassName={style.headlineWrapper}
                      iconElement={CrossIcon}
                      rotate={isActive}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <BigBanner />
      </Layout>
    </>
  );
};

export default Faq;
