import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
  rotate?: number;
}

const Cross = (props: IIconProps) => {
  const { width = 24, height = 24, className = '', rotate = 0 } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      style={{ transform: `rotate(${rotate}deg)` }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 0h24v24H0z" />
        <path fill="#888" d="M4 13v-2h16v2z" />
        <path fill="#888" d="M11 4h2v16h-2z" />
      </g>
    </svg>
  );
};

export default Cross;
