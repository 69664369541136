import React, { useState } from 'react';

import Arrow from '../../images/Arrow';
import CollapsiblePanel from '../CollapsiblePanel/CollapsiblePanel';

import style from '../CollapsiblePanel/CollapsiblePanelList.scss';

interface IIconProps {
  width?: number;
  color?: string;
  color2?: string;
  style?: React.CSSProperties;
  backgroundColor?: string;
  className?: string;
  rotate?: number;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

export interface ICollapsableCalloutProps {
  heading: string;
  content: string;
  icon?: React.ComponentType<IIconProps>;
}

export interface ICollapsableCalloutListProps {
  callouts: ICollapsableCalloutProps[];
  className?: string;
  headlineClassName?: string;
  contentClassName?: string;
  iconElement?: (props: IIconProps) => JSX.Element;
  rotate?: (isActive: boolean) => number;
}

export default function index(props: ICollapsableCalloutListProps) {
  const { callouts, className, iconElement: IconElement, rotate, ...restProps } = props;

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleCollapsiblePanel = (index: number) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  return (
    <>
      {callouts.map((item: any, index: number) => {
        const rotation = rotate && rotate(expandedIndex === index);

        return (
          <CollapsiblePanel
            key={index}
            index={index}
            className={className || style.collapsible}
            contentClassName={props.contentClassName || style.collapsibleContent}
            headlineClassName={props.headlineClassName || style.headlineWrapper}
            headline={item.heading}
            isExpanded={expandedIndex === index}
            onToggle={toggleCollapsiblePanel}
            iconElement={
              IconElement ? (
                <IconElement rotate={rotation} />
              ) : (
                <Arrow rotate={expandedIndex === index ? -180 : 0} />
              )
            }
            {...restProps}
          >
            <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
          </CollapsiblePanel>
        );
      })}
    </>
  );
}
